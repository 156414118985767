<template>
  <div class="edit-user-modal">
    <div class="section left">
      <Form :model="user" :label-width="80">
        <FormItem label="姓名：">
          <Input v-model="user.name" placeholder="..."/>
        </FormItem>
        <FormItem label="用户名：">
          <Input v-model="user.username" placeholder="..." :disabled="isModify"/>
        </FormItem>
        <FormItem label="密码：">
          <Input v-model="user.password" placeholder="..." type="password" password/>
        </FormItem>
        <FormItem label="电话：">
          <Input v-model="user.tel" placeholder="..."/>
        </FormItem>
        <FormItem label="所属">
          <Select v-model="selectOrgId" filterable clearable placeholder="..." style="width: 100%"
                  @on-select="menuItemChange">
            <Option v-for="item in orgList" :value="item.id" :key="item.id">
              {{ item.name }}
            </Option>
          </Select>
        </FormItem>
      </Form>
    </div>

    <!-- 中间区域：配置权限 -->
    <div class="section middle">
      <h3>配置权限</h3>
      <CheckboxGroup v-model="selectRoles" @on-change="checkboxChange">
        <Checkbox v-for="role in roles" :key="role.id" :label="role.id">{{ role.name }}</Checkbox>
      </CheckboxGroup>
    </div>

    <!-- 右侧区域：省份选择 -->
    <div class="section right">
      <h3>选择区域</h3>
      <CheckboxGroup v-model="selectedRegions" @on-change="regionChange">
        <Checkbox v-for="region in regions" :key="region.id" :label="region.id">{{ region.name }}</Checkbox>
      </CheckboxGroup>
    </div>
  </div>


</template>

<script>
import {post} from "@/apis/restUtils"

export default {
  name: "editUserModal",
  data() {
    return {
      user: {
        name: '',
        username: '',
        password: '',
        tel: '',
        orgId: '',
        roles: [],
        regions: [],
      },
      roles: [],
      selectRoles: [],
      selectOrgId: '',
      orgList: [],
      regions: [],      // 新增：省份列表
      selectedRegions: [], // 新增：已选省份
    }
  },
  props: ['row', 'isModify'],
  mounted: function () {
    this.user = this.row;
    if (this.isModify) {
      post("/admin/getUserRoles", this.user, response => {
        this.selectRoles = response.data.entity;
        this.user.roles = this.selectRoles;
      });
      post("/admin/getUserOrganization", this.user, response => {
        console.log("this.user.orgId....", this.user.orgId);
        this.selectOrgId = response.data.orgId;
      });
    }
    post("/admin/getAllRoles", {}, response => {
      this.roles = response.data;
    });
    post("/organization/getOrganiztionOfMenuItem", {}, response => {
      this.orgList = response.data;
    });
    // 新增：获取省份数据
    post("/wx/wxGetRegions", {}, response => {
      this.regions = response.data.entity; // 假设返回格式为 [{ id: 1, name: '北京' }, ...]
    });

    post("/crm/getUserRegions", this.user, response => {
      this.selectedRegions = response.data.entity;
      this.user.regions = this.selectedRegions;
    });
  },
  methods: {
    checkboxChange(data) {
      this.selectRoles = data;
      this.user.roles = this.selectRoles;
    },

    menuItemChange(data) {
      this.selectOrgId = data;
      this.user.orgId = this.selectOrgId;
      console.log("this.user.orgId... ", this.user.orgId, "this.selectOrgId... ", this.selectOrgId);
    },

    // 新增：处理省份选择
    regionChange(data) {
      this.selectedRegions = data;
      this.user.regions = this.selectedRegions;
      // 可选：将已选省份保存到 user 对象或后续提交到后端
    },
  }
}
</script>

<style scoped>
.edit-user-modal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  gap: 20px; /* 区域间距 */
}

.section {
  padding: 15px;
  background-color: #f9f9f9; /* 浅灰背景 */
  border-radius: 8px; /* 圆角 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 阴影效果 */
}

.left {
  flex: 2; /* 左侧占一半宽度 */
}

.middle, .right {
  flex: 1; /* 中间和右侧各占四分之一 */
}

.section h3 {
  margin-bottom: 15px;
  font-size: 16px;
  color: #333;
}

/* 美化复选框 */
.ivu-checkbox-wrapper {
  display: block;
  margin: 8px 0; /* 垂直间距 */
  font-size: 14px;
  color: #555;
}

.ivu-checkbox-inner {
  width: 18px;
  height: 18px;
  border: 2px solid #666; /* 灰色边框 */
  border-radius: 4px; /* 圆角 */
  transition: all 0.2s ease; /* 过渡动画 */
}

.ivu-checkbox-checked .ivu-checkbox-inner {
  background-color: #2db7f5; /* 选中时蓝色背景 */
  border-color: #2db7f5;
}

.ivu-checkbox-checked .ivu-checkbox-inner::after {
  content: '\2713'; /* 对勾符号 */
  color: white;
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>